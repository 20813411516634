import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { ParticleService } from 'src/app/controller/particle.service';
import { DatabaseService } from 'src/app/controller/database.service';
import { InfluxService } from 'src/app/controller/influx.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export interface lectures {
  valor: number;
  fecha: string;
  hora: string;
}

export interface vars {
  sc: string;
  var: string;
  visibilidad: boolean;
}

export interface del {
  sc: string;
  var: string;
}

// Arrays temporales para las tablas
let ELEMENT_DATA: lectures[] = [];
let ELEMENT_DATA_VAR: vars[] = [];
let ELEMENT_DATA_DEL: del[] = [];

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css'],
})
export class DataComponent implements OnInit, OnDestroy, AfterViewInit {
  // Columnas de las tablas
  displayedColumnsTable: string[] = ['valor', 'fecha', 'hora'];
  displayedColumnsGraph: string[] = ['sc', 'var', 'visualizar'];
  displayedColumnsDelete: string[] = ['sc', 'var', 'eliminar'];

  // DataSources
  dataSourceTable = new MatTableDataSource<lectures>(ELEMENT_DATA);
  dataSourceGraph = new MatTableDataSource<vars>(ELEMENT_DATA_VAR);
  dataSourceDelete = new MatTableDataSource<del>(ELEMENT_DATA_DEL);

  // Referencias para ordenar y paginar
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  // Dispositivos, Variables y Estados
  devices: any[] = [];
  fbDevices: any;
  filteredDevices: any[] = [];

  // Usuario actual
  usuario: any = null;

  // Variables seleccionadas
  selectedDevice: string = '';
  selectedVariable: string = '';
  filteredVariables: string[] = [];

  // Para la gráfica
  vectorChart: any[] = []; // Estructura que ngx-charts espera
  addedVariablesChart: any[] = [];
  selectedDeviceChart: string = '';
  selectedVariableChart: string = '';
  filteredVariablesChart: string[] = [];

  // Flags de carga
  loadingTable: boolean = false;
  loadingChart: boolean = false;
  deletingVariable: boolean = false;

  // Control de "Sin variables"
  scSinVariables: boolean = false;
  scSinVariablesChart: boolean = false;

  // Auxiliares
  deviceName: string;
  variableName: string;

  constructor(
    private particle: ParticleService,
    private db: DatabaseService,
    private influx: InfluxService
  ) {
    // Verifica usuario
    this.db.getCurrentUser().subscribe(u => {
      if (u) {
        this.usuario = u;
        console.log('Usuario logueado:', this.usuario);
      }
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  // Limpia los arrays estáticos para evitar datos residuales
  ngOnDestroy(): void {
    ELEMENT_DATA = [];
    ELEMENT_DATA_VAR = [];
    ELEMENT_DATA_DEL = [];
  }

  // Asignamos sort y paginator al dataSource de la tabla al finalizar la vista
  ngAfterViewInit(): void {
    this.dataSourceTable.sort = this.sort;
    this.dataSourceTable.paginator = this.paginator;
  }

  // ---------------------------------------------
  // Inicializa dispositivos y variables
  // ---------------------------------------------
  async getData() {
    await this.delay(500);
    this.getFbDevices();
  }

  getFbDevices() {
    this.db.getCurrentUser().subscribe(user => {
      this.fbDevices = user.devices;
      this.getParticleDevices();
    });
  }

  async getParticleDevices() {
    this.devices = await this.particle.getDevices();
    // Filtra devices que estén en Firebase
    this.fbDevices.forEach(fireId => {
      this.devices.forEach(p => {
        if (fireId === p.id) {
          this.filteredDevices.push(p);
        }
      });
    });

    // Selecciona el primero si hay
    if (this.filteredDevices.length > 0) {
      this.selectedDevice = this.filteredDevices[0].name;
      this.selectedDeviceChart = this.filteredDevices[0].name;

      // Variables para tabla
      await this.getVariablesMainPage(this.selectedDevice);

      // Variables para gráfica
      await this.getVariablesChartPage(this.selectedDeviceChart);

      // Variables para eliminar
      await this.getAllVariables();
    }
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // ---------------------------------------------
  // Variables para TABLA
  // ---------------------------------------------
  async getVariablesMainPage(scName: string) {
    this.deviceName = scName;
    this.filteredVariables = [];
    this.selectedVariable = '';
    this.scSinVariables = false;
    this.loadingTable = true;

    // Resetea la tabla
    ELEMENT_DATA = [];
    this.dataSourceTable.data = ELEMENT_DATA;

    // Carga las variables del SC
    this.filteredVariables = await this.getVariablesSucreCore(scName);

    if (this.filteredVariables.length > 0) {
      this.selectedVariable = this.filteredVariables[0];
      // Carga datos a la tabla
      await this.setTableData(this.selectedDevice, this.selectedVariable);
    } else {
      this.scSinVariables = true;
      this.loadingTable = false;
    }
  }

  // ---------------------------------------------
  // Variables para GRÁFICA
  // ---------------------------------------------
  async getVariablesChartPage(scName: string) {
    this.filteredVariablesChart = [];
    this.selectedVariableChart = '';
    this.scSinVariablesChart = false;

    // Carga las variables
    this.filteredVariablesChart = await this.getVariablesSucreCore(scName);

    if (this.filteredVariablesChart.length > 0) {
      this.selectedVariableChart = this.filteredVariablesChart[0];
    } else {
      this.scSinVariablesChart = true;
    }
  }

  // ---------------------------------------------
  // Consulta a Influx para traer variables
  // ---------------------------------------------
  async getVariablesSucreCore(scName: string) {
    const varVector: string[] = [];
    this.influx.getVariables(scName).subscribe(variables => {
      variables.forEach(variable => {
        varVector.unshift(variable.variable || variable.var);
      });
    });
    // Espera a que la suscripción se llene
    await this.delay(1000);
    return varVector;
  }

  // ---------------------------------------------
  // Leer valores de Influx en formato ngx-charts
  // ---------------------------------------------
  readValues(scName: string, varName: string) {
    const vecDatos = [
      {
        name: `${varName}(${scName})`,
        series: [],
      },
    ];

    this.influx.getValues(scName, varName).subscribe(data => {
      data.forEach(lecture => {
        const date = new Date(lecture.time);
        const numericValue = +lecture.value; // Asegúrate de que sea un número
        vecDatos[0].series.unshift({
          value: numericValue,
          name: date,
        });
      });
    });

    return vecDatos;
  }

  // ---------------------------------------------
  // Actualiza TABLA con la variable seleccionada
  // ---------------------------------------------
  async setTableData(scName: string, varName: string) {
    this.deviceName = scName;
    this.variableName = varName;
    this.loadingTable = true;

    ELEMENT_DATA = [];
    this.dataSourceTable.data = ELEMENT_DATA;

    // Lee los valores en formato ngx-charts
    const vecDatos = this.readValues(scName, varName);
    // Espera un poco para que la suscripción termine
    await this.delay(1000);

    // Rellena la tabla
    vecDatos[0].series.forEach(lecture => {
      const date = new Date(lecture.name);
      ELEMENT_DATA.push({
        valor: lecture.value,
        fecha: date.toLocaleDateString(),
        hora: date.toLocaleTimeString(),
      });
    });

    this.dataSourceTable.data = ELEMENT_DATA;

    // Si deseas volver a la primera página:
    if (this.dataSourceTable.paginator) {
      this.dataSourceTable.paginator.firstPage();
    }

    this.loadingTable = false;
  }

  // ---------------------------------------------
  // Añadir Variable a la tabla de la Gráfica
  // ---------------------------------------------
  async anyadir_variable_a_tabla() {
    this.loadingChart = true;

    const exists = ELEMENT_DATA_VAR.find(
      set => set.sc === this.deviceName && set.var === this.variableName
    );
    if (!exists) {
      const element = {
        sc: this.deviceName,
        var: this.variableName,
        visibilidad: true,
      };
      ELEMENT_DATA_VAR.push(element);
      this.dataSourceGraph.data = ELEMENT_DATA_VAR;

      const vecDatos = this.readValues(element.sc, element.var);
      await this.delay(1000);

      const vecDatos2 = vecDatos.concat(this.vectorChart);
      this.vectorChart = vecDatos2;
      this.addedVariablesChart = vecDatos2;
    }

    this.loadingChart = false;
  }

  // ---------------------------------------------
  // Visibilidad en la gráfica
  // ---------------------------------------------
  async visibilityOn(element: vars) {
    this.loadingChart = true;
    const nombreAdd = element.var + '(' + element.sc + ')';

    const target = this.addedVariablesChart.find(
      set => set.name === nombreAdd
    );
    if (target) {
      // Se vuelve a añadir a vectorChart
      this.vectorChart = this.vectorChart.concat([target]);
    }
    element.visibilidad = true;
    this.loadingChart = false;
  }

  visibilityOff(element: vars) {
    this.loadingChart = true;
    const nombreElim = element.var + '(' + element.sc + ')';
    const target = this.vectorChart.find(set => set.name === nombreElim);

    if (target) {
      this.vectorChart = this.vectorChart.filter(item => item !== target);
    }
    element.visibilidad = false;
    this.loadingChart = false;
  }

  // ---------------------------------------------
  // Carga todas las variables para "Eliminar"
  // ---------------------------------------------
  async getAllVariables() {
    ELEMENT_DATA_DEL = [];
    this.filteredDevices.forEach(device => {
      this.influx.getVariables(device.name).subscribe(variables => {
        variables.forEach(variable => {
          ELEMENT_DATA_DEL.unshift({
            sc: device.name,
            var: variable.var || variable.variable,
          });
        });
      });
    });

    await this.delay(1500);
    this.dataSourceDelete.data = ELEMENT_DATA_DEL;
    this.deletingVariable = false;
  }

  // ---------------------------------------------
  // Eliminar variable de Influx
  // ---------------------------------------------
  async deleteVariable(element: del) {
    if (
      confirm(
        `Se eliminará la variable "${element.var}" del SucreCore "${element.sc}". ¿Desea continuar?`
      )
    ) {
      this.deletingVariable = true;

      // Llamada al servicio
      this.influx
        .deleteVariable(element.sc, element.var)
        .subscribe(data => console.log('deleteVariable:', data));

      const nombreElim = element.var + '(' + element.sc + ')';

      // Quitar de la tabla de gráfica
      const targetTable = ELEMENT_DATA_VAR.find(
        set => set.sc === element.sc && set.var === element.var
      );
      if (targetTable) {
        const target = this.vectorChart.find(
          set => set.name === nombreElim
        );
        if (target) {
          this.vectorChart = this.vectorChart.filter(
            item => item !== target
          );
        }

        // También de la copia
        this.addedVariablesChart = this.addedVariablesChart.filter(
          item => item !== target
        );

        ELEMENT_DATA_VAR = ELEMENT_DATA_VAR.filter(
          item => item !== targetTable
        );
        this.dataSourceGraph.data = ELEMENT_DATA_VAR;
      }

      // Actualiza la tabla principal y gráfica
      await this.delay(800);
      if (element.sc === this.selectedDevice) {
        await this.getVariablesMainPage(this.selectedDevice);
      }
      if (element.sc === this.selectedDeviceChart) {
        await this.getVariablesChartPage(this.selectedDeviceChart);
      }

      // Actualiza la lista de eliminar
      await this.getAllVariables();
    }
  }

  // ---------------------------------------------
  // Descargar PDF de la tabla
  // ---------------------------------------------
  descargarTablaPdf() {
    const elementoTabla = document.getElementById('tablaDatos');
    const docTabla = new jsPDF('p', 'pt', 'a4');

    html2canvas(elementoTabla).then(canvas => {
      const imgagenCanvas = canvas.toDataURL('image/PNG');
      const imgProps = (docTabla as any).getImageProperties(imgagenCanvas);
      const pdfWidth = docTabla.internal.pageSize.getWidth() - 30;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      docTabla.addImage(
        imgagenCanvas,
        'PNG',
        15,
        15,
        pdfWidth,
        pdfHeight
      );
      return docTabla;
    }).then(() => {
      docTabla.save(this.deviceName + '_' + this.variableName + '.pdf');
    });
  }

  // ---------------------------------------------
  // Descargar PDF de la gráfica
  // ---------------------------------------------
  descargarGraficaPdf() {
    const elementoGrafica = document.getElementById('datosGraficaPDF');
    const docGrafica = new jsPDF('p', 'pt', 'a4');
    const opcionesGrafica = { background: 'white', scale: 3 };

    html2canvas(elementoGrafica, opcionesGrafica).then(canvas => {
      const imgagenCanvas = canvas.toDataURL('image/PNG');
      const imgProps = (docGrafica as any).getImageProperties(imgagenCanvas);
      const pdfWidth = docGrafica.internal.pageSize.getWidth() - 30;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      docGrafica.addImage(
        imgagenCanvas,
        'PNG',
        15,
        15,
        pdfWidth,
        pdfHeight,
        undefined,
        'FAST'
      );
      return docGrafica;
    }).then(() => {
      docGrafica.save(this.deviceName + '_graficas.pdf');
    });
  }

  // ---------------------------------------------
  // Descargar CSV de la tabla
  // ---------------------------------------------
  descargarTablaCSV() {
    const tablaMediciones = document.querySelector('#tablaMediciones');
    let stringCsv = 'Valor;Fecha;Hora\n';
    let contador = 0;

    const rows = tablaMediciones.querySelectorAll('td');
    rows.forEach(td => {
      stringCsv += td.textContent + ';';
      contador++;
      if (contador === 3) {
        stringCsv += '\n';
        contador = 0;
      }
    });

    const filename = this.deviceName + '_' + this.variableName + '.csv';
    this.descargarDocCSV(stringCsv, filename);
  }

  // ---------------------------------------------
  // Descargar CSV de los elementos en la gráfica
  // ---------------------------------------------
  descargarElementosGraficaCSV() {
    let csv = 'Variable;FechaHora;Valor\n';
    this.vectorChart.forEach(serie => {
      serie.series.forEach(point => {
        const dt = new Date(point.name);
        csv += `${serie.name};${dt.toLocaleString()};${point.value}\n`;
      });
    });
    const filename = this.deviceName + '_grafica.csv';
    this.descargarDocCSV(csv, filename);
  }

  // ---------------------------------------------
  // Aux: creación de archivo CSV
  // ---------------------------------------------
  descargarDocCSV(csv: string, filename: string) {
    const csvFile = new Blob([csv], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  // ---------------------------------------------
  // Chequeo si la variable ya está en la gráfica
  // ---------------------------------------------
  tablaVariable() {
    return ELEMENT_DATA_VAR.find(
      set => set.sc === this.deviceName && set.var === this.variableName
    );
  }
}
