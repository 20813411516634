import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
  export class InfluxService 
  {
    totalAngularPackages;
    error;
    url: string = 'https://store.programasucre.com/';
    constructor(private http: HttpClient) { }

    getSucreCores()
    {
        return this.http.get<any>(this.url + 'getSucreCores');
    }

    getVariables(scName: String)
    {
        return this.http.get<any>(this.url + 'getVariables/' + scName);
    }

    getValues(scName: String, varName: String)
    {
        return this.http.get<any>(this.url + 'getValues/' + scName + '/' + varName);
    }

    deleteVariable(scName:String, varName: String)
    {
        return this.http.delete<any>(this.url + 'deleteVariable/' + scName + '/' + varName);
    }
  }